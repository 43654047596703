<template>
  <div>
    <topbar
      :title="woDeDiZhi"
      :rightType="2"
      :right="del ? new_WDDZ_wanCheng : new_WDDZ_gengGai"
      category="address"
      @rightClick="rightClick"
    ></topbar>
    <div class="placeList" v-if="placeList.length">
      <div
        :ref="'item_' + index"
        class="itemBox"
        v-for="(item, index) in placeList"
        :key="index"
      >
        <p
          class="del"
          v-show="del"
          @click="showDel(index, item.addressCode)"
        ></p>
        <place-item
          @click="selectPlace(item)"
          :showIcon="item"
          :placeInfo="item"
        ></place-item>
      </div>
    </div>
    <div class="noneData" v-else>
      <none-data :showBtn="false" :title="notSave"></none-data>
    </div>
    <div v-show="showAddBtn" class="addBtn" @click="changePage('sendPlace')">
      {{ addNewAddress }}
    </div>
    <confirm-box
      v-show="showDelFrame"
      :title="DZ_del"
      :cancelBtn="DZ_del_cancel"
      :finishBtn="DZ_del_del"
      :show="true"
      @finish="finish"
      @closeBox="closeBox"
    ></confirm-box>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import PlaceItem from "../../components/myplace/PlaceItem.vue";
import NoneData from "../../components/common/NoneData.vue";
import ConfirmBox from "../../components/common/CnfirmBox.vue";
import { postPaas, postDC } from "../../assets/utils/request";
import language from "../../assets/js/language";
export default {
  name: "Place",
  data() {
    return {
      placeList: [],
      pageParam: null,
      del: false,
      showAddBtn: true,
      showDelFrame: false,
      lanType: this.$store.state.language,
      index: null,
      code: null,
      right: "更改",
      woDeDiZhi: "",
      yiChu: "",
      addNewAddress: "",
      notSave: "",
      new_WDDZ_gengGai: "",
      new_WDDZ_wanCheng: "",
      DZ_del: "",
      DZ_del_cancel: "",
      DZ_del_del: "",
      HQXXSB: "",
      BZPSFWN: "",
    };
  },
  methods: {
    closeBox() {
      this.showDelFrame = false;
    },
    showDel(index, code) {
      this.index = index;
      this.code = code;
      this.showDelFrame = true;
    },
    finish() {
      this.delPlace();
    },
    rightClick() {
      this.del = !this.del;
      this.showAddBtn = !this.showAddBtn;
    },
    async delPlace() {
      var params = {
        actionName: "candao.member.addressDelete",
        content: {
          addressCode: this.code,
        },
      };
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.placeList.splice(this.index, 1);
        this.showDelFrame = false;
        this.index = this.code = null;
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    changePage(path) {
      window.dataLayer.push({
        event: "new_address",
      });
      this.$router.push("/" + path);
    },
    // 获取我的配送地址
    async getMyPlace() {
      var params = {
        actionName: "candao.member.addressGet",
        content: {},
      };
      let placeList = await postPaas("UserUnify", params);
      if (placeList.data.resultCode == "success" && placeList.data.data) {
        this.placeList = placeList.data.data;
      }
    },
    // 选择地址
    async selectPlace(info) {
      if (this.pageParam == 1) {
        return;
      }
      // 获取门店白名单地址
      let whiteListAddressParam = {
        actionName: "candao.storeOwn.listWhiteListAddress",
        content: {
          address: info.storeAddress,
        },
      };
      let whiteListAddress = await postDC("Action", whiteListAddressParam);
      // 如果不再白名单内要查看是否在配送范围内
      if (whiteListAddress.status == 1 && whiteListAddress.data.length == 0) {
        // 查看是否在配送范围内
        let deliveryRangeParam = {
          actionName: "candao.storeStandard.getStoreDeliveryRange",
          content: {
            storeId: JSON.parse(this.pageParam).storeId,
            coordinate: [info.lng, info.lat],
          },
        };
        var deliveryRange = await postDC("Action", deliveryRangeParam);
      }
      // // 白名单调用成功 继续下一步
      if (whiteListAddress.status == 1) {
        if (
          whiteListAddress.data.length &&
          deliveryRange.data &&
          deliveryRange.data.length
        ) {
          this.$store.commit("savePlace", info);
          this.$router.back(-1);
        } else {
          this.$toast(this.BZPSFWN);
        }
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    dataLayerPush() {
      window.dataLayer.push({
        event: "address",
        ecommerce: {
          Address_count: this.placeList.length,
          Address_variant: "",
        },
      });
    },
    setLanguage() {
      var type = "chinese";
      if (this.lanType != 1) {
        type = "english";
      }
      this.woDeDiZhi = language.woDeDiZhi[type];
      this.yiChu = language.yiChu[type];
      this.addNewAddress = language.addNewAddress[type];
      this.notSave = language.notSave[type];
      this.new_WDDZ_gengGai = language.new_WDDZ_gengGai[type];
      this.new_WDDZ_wanCheng = language.new_WDDZ_wanCheng[type];
      this.DZ_del = language.DZ_del[type];
      this.DZ_del_cancel = language.DZ_del_cancel[type];
      this.DZ_del_del = language.DZ_del_del[type];
      this.HQXXSB = language.HQXXSB[type];
      this.BZPSFWN = language.BZPSFWN[type];
    },
  },
  created() {
    this.setLanguage();
    this.getMyPlace();
    var params = this.$route.params.params;
    this.pageParam = params;
  },

  components: {
    Topbar,
    PlaceItem,
    NoneData,
    ConfirmBox,
  },
};
</script>
<style scoped>
.placeList {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  padding-bottom: 1rem;
}
.delete-button {
  width: 0.5rem;
  height: 100%;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 20px;
  color: #e4022b;
  line-height: 1rem;
}
.addBtn {
  width: 3.4rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
  background-color: #e4022b;
  position: fixed;
  bottom: 0.2rem;
  left: 50%;
  transform: translateX(-50%);
}
.noneData {
  height: 80vh;
}
.itemBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.del {
  width: 0.3rem;
  height: 0.24rem;
  background: url("../../assets/icon/del.png") no-repeat center;
  background-size: 0.24rem 0.24rem;
  margin-right: 0.16rem;
}
</style>